import React from 'react';

const Footer = () => {
  return (
    <div>
      <div className="container-fluid footer mt-5 py-5 wow fadeIn" 
        data-wow-delay="0.1s" 
        style={{
          background: 'linear-gradient(to right, #141E30, #243B55)', 
          visibility: 'visible', 
          animationDelay: '0.1s', 
          animationName: 'fadeIn'
        }}>
        <div className="container py-5">
          <div className="row g-5">
            <div className="col-lg-3 col-md-6">
              <h4 className="text-white mb-4">Our Office</h4>
              <p className="mb-2 text-white"><i className="fa fa-map-marker-alt me-3" />123 Street, New York, USA</p>
              <p className="mb-2 text-white"><i className="fa fa-phone-alt me-3" />+012 345 67890</p>
              <p className="mb-2 text-white"><i className="fa fa-envelope me-3" />info@example.com</p>              
              <div className="d-flex pt-3">
                <a className="btn btn-square btn-light rounded-circle me-2" href="#"><i className="fab fa-twitter" /></a>
                <a className="btn btn-square btn-light rounded-circle me-2" href="#"><i className="fab fa-facebook-f" /></a>
                <a className="btn btn-square btn-light rounded-circle me-2" href="#"><i className="fab fa-youtube" /></a>
                <a className="btn btn-square btn-light rounded-circle me-2" href="#"><i className="fab fa-linkedin-in" /></a>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
  <h4 className="text-white mb-4">Quick Links</h4>
  <ul className="list-unstyled">
  <li><a className="text-light btn btn-link text-decoration-none" href="#">About Us</a></li>
  <li><a className="text-light btn btn-link text-decoration-none" href="#">Contact Us</a></li>
  <li><a className="text-light btn btn-link text-decoration-none" href="#">Our Services</a></li>
  <li><a className="text-light btn btn-link text-decoration-none" href="#">Terms &amp; Conditions</a></li>
  <li><a className="text-light btn btn-link text-decoration-none" href="#">Support</a></li>
</ul>
</div>

            <div className="col-lg-3 col-md-6">
            <h4 className="text-white mb-4">Business Hours</h4>
<p className="mb-1 text-secondary">Monday - Friday</p>
<h6 className="text-light">09:00 am - 07:00 pm</h6>
<p className="mb-1 text-secondary">Saturday</p>
<h6 className="text-light">09:00 am - 12:00 pm</h6>
<p className="mb-1 text-secondary">Sunday</p>
<h6 className="text-light">Closed</h6>

            </div>
            <div className="col-lg-3 col-md-6">
              <h4 className="text-white mb-4">Newsletter</h4>
              <p className="text-light">
                Get in touch with 1hav               </p>
                

              <div className="position-relative w-100">
                <input className="form-control bg-transparent w-100 py-3 ps-4 pe-5 text-white" type="text" placeholder="Your email"  />
                  <button type="button" className="btn btn-primary py-2 position-absolute top-0 end-0 mt-2 me-2">Sign Up</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid copyright py-4" style={{background: '#111'}}>
        <div className="container">
          <div className="row text-light">
            <div className="col">
              © <a className="fw-medium text-light" href="#">1hav</a>, All Right Reserved.
            </div>
            <div className="col fs-6 text-center">
              Developed By <a className="fw-medium text-light" target="_blank" href="https://haassaaaannnn.netlify.app/">1hav Software Company</a> 
            </div>
            <div className="col text-end">
              template author's <a className="fw-medium text-light">1hav</a> 
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
